import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Badge,
} from "reactstrap";
import { withFirebase } from "../../../../containers/Firebase";
import { isBlank } from "../../../../utilities/core";
import {
  imageUploadInputStyle,
  imageStyle,
  labelStyle,
} from "../../utilities/PortalFormStyles";
import Select from "react-select";
import { dbLoadTeams } from "../../../../containers/DatabaseHelpers";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      picture: "",
      pictureFile: null,
      picturePath: null,
      pictureURL: "",
      address: "",
      alertRadius: "",
      description: "",
      email: "",
      displayEmail: "",
      phone: "",
      lat: "",
      long: "",
      city: "",
      title: "",
      offers: [],
      redeemRadius: "",
      uuid: "",
      website: "",
      linkedin: "",
      twitter: "",
      facebook: "",
      instagram: "",
      category: "",
      keywords: "",
      flashDealPercentageOverride: 0,
      teams: null,
    };

    this.pictureClick = this.pictureClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushSponsorData = this.pushSponsorData.bind(this);
    this.loadSponsor = this.loadSponsor.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.setState(
      { sponsorID: this.props.match.params.sponsor_id },
      this.loadSponsor
    );
    this.loadTeams();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.teams && this.state.teams) {
      this.mapTeamList();
    }
  }

  loadTeams = () => {
    const { firebase } = this.props;

    dbLoadTeams(firebase, (teamsArray) => {
      const allTeamsMap = {};
      teamsArray.forEach((team) => {
        allTeamsMap[team.key] = team;
      });

      const teamsForSelect = teamsArray
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((t) => ({ value: t.key, label: t.name }));

      this.setState({ allTeamsMap, teamsForSelect });
    });
  };

  mapTeamList = () => {
    const { allTeamsMap, teams } = this.state;
    if (!allTeamsMap) return;
    var defaultValue = [];
    if (teams) {
      Object.entries(teams).forEach(([t, v]) => {
        const team = allTeamsMap[t];
        if (team) {
          defaultValue.push({ value: team.key, label: team.name });
        }
      });
    }
    this.setState({ selectedOption: defaultValue });
    this.handleSelectChange(defaultValue);
  };

  loadSponsor() {
    const { sponsorID } = this.state;

    const sponsorPath = "/sponsor/" + sponsorID;
    const sponsorRef = this.props.firebase.database.ref(sponsorPath);

    sponsorRef.once("value").then((dataSnapshot) => {
      var sponsor = dataSnapshot.val();
      this.setState({
        picturePath: sponsor.picture ? sponsor.picture : "",
        pictureURL: sponsor.picture ? sponsor.picture : "",
        address: sponsor.address,
        alertRadius: sponsor["alert radius"],
        description: sponsor.description,
        lat: sponsor.lat,
        long: sponsor.long,
        city: sponsor.city ? sponsor.city : "",
        title: sponsor.name,
        redeemRadius: sponsor["redeem radius"],
        uuid: sponsor.sponsorUser,
        offers: sponsor.offers ? sponsor.offers : [],
        email: sponsor.email,
        displayEmail: sponsor.displayEmail,
        phone: sponsor.phone,
        website: sponsor.website,
        facebook: sponsor.facebook,
        linkedin: sponsor.linkedin,
        twitter: sponsor.twitter,
        instagram: sponsor.instagram,
        category: sponsor.category,
        keywords: sponsor.keywords,
        flashDealPercentageOverride: sponsor.flashDealPercentageOverride || 90,
        teams: sponsor.teams ? sponsor.teams : {},
      });
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    const files = e.target.files;
    if (files) {
      let fileName = name + "File";
      let filePath = name + "Path";
      let file = files[0];
      this.setState({ [fileName]: file });
      this.setState({ [filePath]: URL.createObjectURL(file) });
    } else {
      this.setState({ [name]: value });
    }
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  pictureClick() {
    document.getElementById("picture_upload").click();
  }

  validateForm() {
    const { lat, long } = this.state;

    if (isBlank(lat)) {
      alert("Please enter a latitude value");
      return false;
    }

    if (isBlank(long)) {
      alert("Please enter a longitude value");
      return false;
    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    let sponsorStorageRef = this.props.firebase.storage.ref();
    let sponsorStorageBasePath = "images/sponsor/";

    var sponsorID = this.state.sponsorID;
    var ref = this.props.firebase.database.ref("sponsor/" + sponsorID);
    sponsorStorageBasePath += sponsorID + "/";

    var picturePath = sponsorStorageBasePath + "profile.jpg";

    if (this.state.pictureFile) {
      sponsorStorageRef
        .child(picturePath)
        .put(this.state.pictureFile)
        .then((dataSnapshot) => dataSnapshot.ref.getDownloadURL())
        .then((url) => {
          this.setState({ pictureURL: url });

          if (this.state.uuid && this.state.uuid.length > 0) {
            var userPath = "user/" + this.state.uuid;
            var userRef = this.props.firebase.database.ref(userPath);
            var payload = { sponsorID, role: "sponsor" };

            userRef.set(payload).then(() => {
              this.pushSponsorData(ref);
            });
          } else {
            this.pushSponsorData(ref);
          }
        });
    } else {
      this.pushSponsorData(ref);
    }
  }

  pushSponsorData(ref) {
    const {
      title,
      description,
      address,
      alertRadius,
      redeemRadius,
      lat,
      long,
      city,
      pictureURL,
      uuid,
      offers,
      website,
      phone,
      email,
      displayEmail,
      facebook,
      linkedin,
      twitter,
      instagram,
      category,
      keywords,
      flashDealPercentageOverride,
      teams,
    } = this.state;

    const sponsorData = {
      name: title,
      description: description,
      address: address,
      "alert radius": alertRadius ? parseInt(alertRadius) : alertRadius,
      "redeem radius": redeemRadius ? parseInt(redeemRadius) : redeemRadius,
      lat: lat ? parseFloat(lat) : lat,
      long: long ? parseFloat(long) : long,
      city: city,
      picture: pictureURL,
      sponsorUser: uuid,
      offers: offers,
      website: website,
      phone: phone,
      email: email,
      displayEmail: displayEmail,
      facebook: facebook,
      linkedin: linkedin,
      twitter: twitter,
      instagram: instagram,
      category: category,
      keywords: keywords,
      flashDealPercentageOverride: flashDealPercentageOverride,
      teams: teams,
    };

    ref.update(sponsorData).then(() => {
      this.props.history.push("/portal/sponsors");
    });
  }

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption });
    const teams = {};
    selectedOption.forEach((option) => {
      teams[option.value] = { key: option.value, name: option.label };
    });
    this.setState({ teams });
  };

  render() {
    const { teamsForSelect } = this.state;
    return (
      <div className="animated fadeIn" id="main">
        <Form onSubmit={(event) => this.handleSubmit(event)}>
          <div className="row">
            <div className="col-12">
              <FormGroup className="text-center">
                <Label style={labelStyle}>
                  <strong>PICTURE</strong>
                </Label>
                <img
                  src={this.state.picturePath}
                  style={imageStyle}
                  onClick={this.pictureClick}
                  alt="Sponsor"
                ></img>
                <input
                  name="picture"
                  onChange={(event) => this.handleUserInput(event)}
                  id="picture_upload"
                  type="file"
                  style={imageUploadInputStyle}
                />
              </FormGroup>
            </div>
          </div>

          <div className="form-section">
            <h2>Basic Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TITLE (Full Name)</Label>
                  <Input
                    name="title"
                    value={this.state.title}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>DESCRIPTION</Label>
                  <Input
                    name="description"
                    value={this.state.description}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Team</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <Label>SELECT TEAM</Label>
                <Select
                  value={this.state.selectedOption}
                  onChange={this.handleSelectChange}
                  options={teamsForSelect}
                  isMulti={true}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: state.isFocused ? "#464646" : "#b3b3b3",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Contact Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>WEBSITE URL</Label>
                  <Input
                    name="website"
                    value={this.state.website}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>PHONE NUMBER</Label>
                  <Input
                    name="phone"
                    value={this.state.phone}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>EMAIL</Label>
                  <Input
                    name="email"
                    value={this.state.email}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>DISPLAY EMAIL</Label>
                  <Input
                    name="displayEmail"
                    value={this.state.displayEmail}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>User UID</Label>
                  <Input
                    name="uuid"
                    value={this.state.uuid}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Address Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <FormGroup>
                  <Label>ADDRESS</Label>
                  <Input
                    name="address"
                    value={this.state.address}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>CITY</Label>
                  <Input
                    name="city"
                    value={this.state.city}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LATITUDE</Label>
                  <Input
                    name="lat"
                    value={this.state.lat}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LONGITUDE</Label>
                  <Input
                    name="long"
                    value={this.state.long}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Deal Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>ALERT RADIUS</Label>
                  <Input
                    name="alertRadius"
                    value={this.state.alertRadius}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>REDEEM RADIUS</Label>
                  <Input
                    name="redeemRadius"
                    value={this.state.redeemRadius}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>
                    Flash Deal Percentage Override (%){" "}
                    <Badge color="dark" id="flash-deal-override-icon">
                      !
                    </Badge>
                  </Label>
                  <UncontrolledTooltip
                    placement="right"
                    target="flash-deal-override-icon"
                  >
                    Please note, payment processor fees may lower the actual
                    amount transferred.
                  </UncontrolledTooltip>
                  <Input
                    name="flashDealPercentageOverride"
                    value={this.state.flashDealPercentageOverride}
                    onChange={(event) => this.handleUserInput(event)}
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Social Links</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>FACEBOOK PROFILE URL</Label>
                  <Input
                    name="facebook"
                    value={this.state.facebook}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LINKEDIN PROFILE URL</Label>
                  <Input
                    name="linkedin"
                    value={this.state.linkedin}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TWITTER PROFILE URL</Label>
                  <Input
                    name="twitter"
                    value={this.state.twitter}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>INSTAGRAM PROFILE URL</Label>
                  <Input
                    name="instagram"
                    value={this.state.instagram}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Search Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>Category</Label>
                  <Input
                    name="category"
                    value={this.state.category}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>Keywords</Label>
                  <Input
                    name="keywords"
                    value={this.state.keywords}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="row">
              <div className="col-12">
                <Input type="submit" value="Submit" />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default withFirebase(Edit);
