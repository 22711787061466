/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { withRouter } from "react-router-dom";
import OfferPreview from "../../views/Portal/Sponsors/Show/OfferPreview";
import {
  DashboardButton,
  DashboardCard,
  DashboardCardHeader,
  FlexContainer,
  COLORS,
  ToggleButton,
  SpinLoader,
} from "../../assets/fansaves-ui";
import { Icon } from "@iconify/react";

import { toggleDealStatus } from "../../utilities/dashboard_helpers";

import "./DashboardDealCard.scss";
import moment from "moment";
import { isSponsor, isAdmin, isTeam } from "../Session";
import "../../../src/scss/base/typography.scss";

import useRedemptionsStore from "../../stores/redemptionsStore";

const DashboardDealCard = ({
  deal: initialDeal,
  authUser,
  hideViewButton,
  dealId,
  firebase,
  history,
  match,
  location,
}) => {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const fetchRedemptionsByChunks = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const isRedemptionsLoading = useRedemptionsStore((state) => state.isLoading);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [redemptions, setRedemptions] = useState(null);
  const [redemptionCount, setRedemptionCount] = useState(null);
  const [contestEntriesCount, setContestEntriesCount] = useState(null);
  const [deal, setDeal] = useState(initialDeal);

  useEffect(() => {
    if (!deal) {
      setDeal(initialDeal);
    }
  }, [deal]);

  useEffect(() => {
    if (!redemptionsFromEvents) {
      if (authUser && isAdmin(authUser)) {
        fetchRedemptionsByChunks(firebase);
      } else {
        fetchRedemptions(firebase);
      }
    }
  }, [
    fetchRedemptions,
    firebase,
    redemptionsFromEvents,
    authUser,
    fetchRedemptionsByChunks,
  ]);

  useEffect(() => {
    if (!isRedemptionsLoading && redemptionsFromEvents && deal) {
      setRedemptions(
        redemptionsFromEvents.filter((redemption) => {
          return (
            (redemption.offerID === deal.key ||
              redemption.offerID === dealId) &&
            deal.sponsor &&
            deal.sponsor.sponsorKey === redemption.sponsorID &&
            deal.teams &&
            deal.teams[0] === redemption.teamID
          );
        })
      );
    }
  }, [redemptionsFromEvents, deal, dealId, isRedemptionsLoading]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const countRedemptions = () => {
    if (redemptions && deal) {
      const id = deal.key || dealId;
      const count = redemptions.filter((redemption) => {
        if (
          redemption.offerID === id &&
          redemption.transactionType === "redemption"
        ) {
          return redemption;
        }
        return false;
      }).length;
      return count;
    }
  };

  const countContestEntries = () => {
    if (redemptions && deal) {
      const id = deal.key || dealId;
      const count = redemptions.filter((redemption) => {
        if (
          redemption.offerID === id &&
          redemption.transactionType === "contest"
        ) {
          return redemption;
        }
        return false;
      }).length;
      return count;
    }
  };

  useEffect(() => {
    if (redemptions && deal) {
      const count = countRedemptions();
      const contestEntriesCount = countContestEntries();
      setRedemptionCount(count);
      setContestEntriesCount(contestEntriesCount);
    }
  }, [redemptions]);

  const getIconColor = () => {
    if (authUser && isTeam(authUser)) {
      return COLORS.primaryBlue;
    }
    if (deal.published && deal.active) {
      return COLORS.primaryGreen;
    } else if (deal.published && !deal.active) {
      return COLORS.yellow;
    } else {
      return COLORS.chartRed;
    }
  };
  const renderIcon = () => {
    if (deal.triggered) {
      return <Icon icon="bi:bell-fill" style={{ color: getIconColor() }} />;
    } else if (deal.flashDeal) {
      return (
        <Icon
          icon="fa6-solid:bolt-lightning"
          style={{ color: getIconColor() }}
        />
      );
    } else if (deal.exclusive) {
      return <Icon icon="fa:lock" style={{ color: getIconColor() }} />;
    } else {
      return (
        <Icon icon="fa-solid:shopping-bag" style={{ color: getIconColor() }} />
      );
    }
  };

  const getViewPath = () => {
    if (deal.sponsor && deal.sponsor.sponsorKey) {
      return `/portal/sponsors/${deal.sponsor.sponsorKey}/deals/${
        deal.key ? deal.key : dealId
      }`;
    } else {
      return null;
    }
  };

  const renderButtons = () => {
    if (deal.triggered) {
      return (
        <>
          {!hideViewButton && (
            <DashboardButton
              title="View"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                history.push(getViewPath());
              }}
            />
          )}
          {isTeam(authUser) && !deal.active && deal.published && (
            <DashboardButton
              title="Trigger"
              size="small"
              type="green"
              onClick={(e) => {
                e.preventDefault();
                setConfirmationModal(true);
              }}
            />
          )}
          {isTeam(authUser) && deal.active && deal.published && (
            <DashboardButton
              title="Untrigger"
              size="small"
              type="green"
              onClick={(e) => {
                e.preventDefault();
                toggleDealStatusHandler(deal, "active");
              }}
            />
          )}
          {isAdmin(authUser) && (
            <DashboardButton
              title="Edit"
              size="small"
              type="white"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/portal/sponsors/${
                    deal.sponsor.sponsorKey
                  }/deals/${deal.key ? deal.key : dealId}/edit`,
                  state: { from: location.pathname },
                });
              }}
            />
          )}
        </>
      );
    } else if (deal.published) {
      if (deal.flashDeal) {
        return (
          <>
            {!hideViewButton && (
              <DashboardButton
                title="View"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(getViewPath());
                }}
              />
            )}
            {isSponsor(authUser) && (
              <DashboardButton
                title="Purchases"
                type="white"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    `/portal/sponsors/${deal.sponsor.sponsorKey}/deals/${
                      deal.key ? deal.key : dealId
                    }/purchases`
                  );
                }}
              />
            )}
            {isAdmin(authUser) && (
              <DashboardButton
                title="Edit"
                size="small"
                type="white"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/portal/sponsors/${
                      deal.sponsor.sponsorKey
                    }/deals/${deal.key ? deal.key : dealId}/edit`,
                    state: { from: location.pathname },
                  });
                }}
              />
            )}
          </>
        );
      }
    } else {
      return (
        <>
          {!hideViewButton && (
            <DashboardButton
              title="View"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                history.push(getViewPath());
              }}
            />
          )}
          {isAdmin(authUser) && (
            <DashboardButton
              title="Edit"
              type="white"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/portal/sponsors/${
                    deal.sponsor.sponsorKey
                  }/deals/${deal.key ? deal.key : dealId}/edit`,
                  state: { from: location.pathname },
                });
              }}
            />
          )}
        </>
      );
    }
    return (
      <>
        {!hideViewButton && (
          <DashboardButton
            title="View"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              history.push(getViewPath());
            }}
          />
        )}
        {isAdmin(authUser) && (
          <DashboardButton
            title="Edit"
            size="small"
            type="white"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/portal/sponsors/${deal.sponsor.sponsorKey}/deals/${
                  deal.key ? deal.key : dealId
                }/edit`,
                state: { from: location.pathname },
              });
            }}
          />
        )}
      </>
    );
  };

  const getHeaderType = () => {
    if (deal.published && deal.active) {
      return "active";
    } else if (deal.published && !deal.active) {
      return "inactive";
    } else if (!deal.published) {
      return "archived";
    }
  };

  const getLeftHeaderTitle = () => {
    if (deal.triggered && deal.active && deal.published && !isAdmin(authUser)) {
      return "Triggered";
    }
    if (
      deal.triggered &&
      !deal.active &&
      deal.published &&
      !isAdmin(authUser)
    ) {
      return "Not Triggered";
    }
    if (
      deal.published &&
      (!deal.triggered || (deal.triggered && isAdmin(authUser)))
    ) {
      return "Visible";
    }
    if (!deal.published) {
      return "Archived";
    }
  };

  const getRightHeaderTitle = () => {
    if (deal.triggered && deal.active && !isAdmin(authUser)) {
      return moment(deal.activatedAt).format("MMM D YYYY, h:mm a");
    }
    if (
      (!deal.triggered || (deal.triggered && isAdmin(authUser))) &&
      deal.active &&
      deal.published
    ) {
      return "Redeemable";
    }
    if (
      (!deal.triggered || (deal.triggered && isAdmin(authUser))) &&
      !deal.active &&
      deal.published
    ) {
      return "Not Redeemable";
    }
  };

  const toggleDealStatusHandler = (offer, status) => {
    if (!offer.key) {
      offer.key = dealId;
    }

    toggleDealStatus(
      offer,
      status,
      firebase,
      () => {
        console.log("Deal status toggled successfully");
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  const getDealType = () => {
    let dealType = "";
    if (deal.triggered) {
      dealType = "Triggered";
    } else if (deal.exclusive) {
      dealType = "Exclusive";
    } else if (deal.flashDeal) {
      dealType = "Flash";
    }
    if (deal.dealType) {
      if (deal.dealType === "online") {
        dealType += " Online";
      } else if (deal.dealType === "instore") {
        dealType += " In-Store";
      }
    }

    dealType += " Deal";

    return dealType;
  };

  return (
    <div className="dashboard-deal-card-component">
      {isAdmin(authUser) && deal.triggered && deal.active && (
        <DashboardCardHeader
          type="admin"
          top="-25px"
          left="0"
          padding="5px 0px"
          width="35%"
          mobileWidth="100%"
        >
          <FlexContainer
            direction="horizontal"
            justifyContent="flex-start"
            alignItems="center"
            gap="10px"
          >
            <span className="inter-b2 admin-header-text">TRIGGERED</span>
            <span className="inter-b2 admin-header-text">
              {moment(deal.activatedAt).format("MMM D, YYYY h:mma")}
            </span>
          </FlexContainer>
        </DashboardCardHeader>
      )}
      <DashboardCardHeader
        type={getHeaderType()}
        top="-25px"
        right="0"
        padding="5px 20px"
        width="35%"
        mobileWidth="100%"
      >
        <FlexContainer
          direction="horizontal"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <FlexContainer
            direction="horizontal"
            className="dashboard-deal-card-status content inter-b2"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="8px"
          >
            <div className="dashboard-deal-card-status">
              {getLeftHeaderTitle() && getLeftHeaderTitle().toUpperCase()}
            </div>
            {isAdmin(authUser) && (
              <ToggleButton
                checked={deal.published}
                onChange={() => toggleDealStatusHandler(deal, "published")}
                backgroundColor={COLORS.primaryBlue}
                titleColor={COLORS.primaryWhite}
              />
            )}
          </FlexContainer>
          <FlexContainer
            direction="horizontal"
            className="dashboard-deal-card-status-container content inter-b2"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="4px"
          >
            <div className="dashboard-deal-card-status">
              {getRightHeaderTitle() && getRightHeaderTitle().toUpperCase()}
            </div>
            {isAdmin(authUser) && deal.published && (
              <ToggleButton
                checked={deal.active}
                onChange={() => toggleDealStatusHandler(deal, "active")}
                backgroundColor={COLORS.primaryBlue}
                titleColor={COLORS.primaryWhite}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </DashboardCardHeader>

      <DashboardCard
        shadowStyle
        className="dashboard-deal-card-container"
        width="100%"
      >
        <FlexContainer
          className="deal-card-container"
          direction="horizontal"
          gap="20px"
          justifyContent="space-between"
          alignItems="center"
          padding="20px"
          responsive
        >
          <FlexContainer
            direction="vertical"
            className="deal-card-image-container"
          >
            <img src={deal.picture} alt={deal.description} />
          </FlexContainer>
          <FlexContainer
            direction="vertical"
            alignItems="flex-start"
            className="deal-card-content-container"
          >
            <FlexContainer
              direction="horizontal"
              gap="20px"
              justifyContent="flex-start"
              className="deal-card-title-container"
              padding="0"
            >
              <span className="deal-card-title-text public-h1-bold">
                {deal.description}
              </span>
              <span className="deal-card-title-icon">{renderIcon()}</span>
            </FlexContainer>
            {(isAdmin(authUser) || isTeam(authUser)) && (
              <div
                className="deal-card-business-name public-h1"
                data-notranslate
              >
                {deal.sponsor && deal.sponsor.name}
              </div>
            )}
            <FlexContainer
              className="deal-card-description public-h2"
              direction="horizontal"
              justifyContent="flex-start"
              alignItems="center"
              width="fit-content"
              gap="5px"
            >
              {deal.contestDeal ? (
                <>
                  Entries:{" "}
                  {contestEntriesCount || contestEntriesCount === 0
                    ? contestEntriesCount
                    : renderLoading()}
                </>
              ) : (
                <>
                  Redemptions:{" "}
                  {redemptionCount || redemptionCount === 0
                    ? redemptionCount
                    : renderLoading()}
                </>
              )}
            </FlexContainer>
            <div className="deal-card-created-at public-h2">
              <span className="deal-type">{getDealType()}</span>
              {deal.published ? (
                <>
                  {deal.active ? (
                    <span>
                      {" "}
                      Active Since:{" "}
                      {deal.activatedAt
                        ? moment(deal.activatedAt).format("YYYY-MM-DD")
                        : moment(deal.activeAt).format("YYYY-MM-DD")}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      Published Since:{" "}
                      {deal.publishedAt
                        ? moment(deal.publishedAt).format("YYYY-MM-DD")
                        : "N/A"}
                    </span>
                  )}
                </>
              ) : (
                <span>
                  {" "}
                  Archived Since:{" "}
                  {deal.archivedAt
                    ? moment(deal.archivedAt).format("YYYY-MM-DD")
                    : "N/A"}
                </span>
              )}
            </div>
          </FlexContainer>

          <FlexContainer
            direction="horizontal"
            gap="20px"
            justifyContent="space-around"
            className="deal-card-actions-container"
            padding="0"
          >
            {renderButtons()}
          </FlexContainer>
          {isAdmin(authUser) && deal.published && (
            <FlexContainer
              direction="horizontal"
              gap="10px"
              alignItems="flex-start"
              justifyContent="flex-end"
              width="100%"
              className="deal-card-pin-container"
            >
              <div className="inter-b1 pinned-text">
                {deal.pinned ? "Pinned" : "Pin"}
              </div>
              <ToggleButton
                checked={deal.pinned}
                onChange={() => toggleDealStatusHandler(deal, "pinned")}
                backgroundColor={
                  deal.pinned ? COLORS.primaryGreen : COLORS.primaryBlue
                }
                titleColor={COLORS.primaryBlue}
                className="dashboard-deal-card-pin-toggle"
              />
            </FlexContainer>
          )}
          {!isAdmin(authUser) && isTeam(authUser) && deal.pinned && (
            <FlexContainer
              direction="horizontal"
              gap="10px"
              alignItems="flex-start"
              justifyContent="flex-end"
              width="100%"
              className="deal-card-pin-container"
              padding="0 0 10px 0"
            >
              <div className="inter-b1 pinned-text">Pinned</div>
            </FlexContainer>
          )}
        </FlexContainer>
        {confirmationModal && (
          <OfferPreview
            offerKey={deal.key}
            onCancel={() => setConfirmationModal(false)}
            onClose={() => setConfirmationModal(false)}
            onActivate={(e) => {
              e.preventDefault();
              setConfirmationModal(false);
              toggleDealStatusHandler(deal, "active");
            }}
          />
        )}
      </DashboardCard>
    </div>
  );
};

export default withAuthentication(withFirebase(withRouter(DashboardDealCard)));

DashboardDealCard.propTypes = {
  deal: propTypes.object.isRequired,
  authUser: propTypes.object,
  hideViewButton: propTypes.bool,
  dealId: propTypes.string,
};

DashboardDealCard.defaultProps = {
  hideViewButton: false,
};
