/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import {
  FlexContainer,
  CSVExport,
  SpinLoader,
  DashboardTitle,
  GLOBALS,
} from "../../../../assets/fansaves-ui";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import moment from "moment";

import DashboardFollowersTable from "../../../../containers/DashboardFollowersTable";
import { DashboardHeader } from "../../../../containers/UIKit";

import useTeamsStore from "../../../../stores/teamsStore";

import {
  getLocation,
  calculateAge,
} from "../../../../utilities/dashboard_helpers";

const StyledfollowersList = styled.div`
  margin-bottom: 50px;
  .component-dashboard-redemptions-table {
    .component-dashboard-filter {
      top: -20px;
    }
  }
  .followers-list-header {
    position: relative;
    .link-container {
      position: absolute;
      top: 16px;
      right: 10px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .link-container {
        position: relative;
        margin-left: 30px;
      }
    }
  }
`;

const FollowersList = ({ firebase, match }) => {
  const teamId = match.params.team_id;

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);

  const [teamOffers, setTeamOffers] = useState([]);
  const [followers, setFollowers] = useState(null);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if (teamId) {
      if (!team || team.key !== teamId) {
        fetchTeam(firebase, teamId);
      }
    }
  }, [firebase, team, teamId]);

  useEffect(() => {
    if (teamId) {
      firebase.firestore
        .collection("offers")
        .where("teams", "array-contains", teamId)
        .get()
        .then((snapshot) => {
          const offers = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setTeamOffers(offers);
        });
    }
  }, [firebase, teamId]);

  const getTeamFollowers = () => {
    const teamFollowers = [];
    if (
      teamOffers.some((offer) => offer.teams && !offer.teams.includes(teamId))
    ) {
      return;
    }
    const { users, followers } = team;
    if (followers && users) {
      Object.entries(team.followers).forEach(([key, value]) => {
        let localTime;
        if (value) {
          localTime = moment.utc(value).tz("Canada/Eastern");
        }
        const user = users.find((user) => user.key === key);
        if (user) {
          let lastRedemption;
          teamOffers.forEach((offer) => {
            if (user.redeemed) {
              Object.entries(user.redeemed).forEach(([dealKey, date]) => {
                if (dealKey === offer.key) {
                  if (!lastRedemption) {
                    lastRedemption = date;
                  } else {
                    if (moment(date).isAfter(lastRedemption)) {
                      lastRedemption = date;
                    }
                  }
                }
              });
            }
          });
          teamFollowers.push({
            ...user,
            lastRedemption: lastRedemption,
            followedAt: localTime,
          });
        }
      });
    }

    teamFollowers.sort((a, b) => {
      return moment(b.followedAt).diff(moment(a.followedAt));
    });

    setFollowers(teamFollowers);
  };

  useEffect(() => {
    if (team && teamOffers?.length > 0) {
      getTeamFollowers();
    }
  }, [team, teamOffers]);

  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Age", key: "age" },
      { label: "Gender", key: "gender" },
      { label: "Location", key: "location" },
      { label: "Account Created", key: "accountCreated" },
      { label: "Date Followed", key: "dateFollowed" },
      { label: "Date of Last Redemption", key: "dateOfLastRedemption" },
    ];
  };

  const generateData = () => {
    if (!followers) return null;
    const data = followers.reduce((acc, follower) => {
      const {
        email,
        createdAt,
        gender,
        followedAt,
        zip,
        dob,
        city: userCity,
        state,
        lastRedemption,
        name,
      } = follower;

      const age = calculateAge(dob);
      let city = userCity ? (state ? `${userCity}, ${state}` : userCity) : null;
      if (!city) {
        const { city: userCity } = getLocation(zip);
        city = userCity;
      }

      const followerData = {
        index: acc.length + 1,
        name: name || "N/A",
        email: email || "N/A",
        age: age || "N/A",
        gender: gender || "N/A",
        location: city || "N/A",
        accountCreated: createdAt
          ? moment(createdAt).format("YYYY/MM/DD HH:mm A")
          : "N/A",
        dateFollowed: followedAt
          ? moment(followedAt, "YYYY-MM-DD:hh:mm:ss").format(
              "YYYY/MM/DD HH:mm A"
            )
          : "N/A",
        dateOfLastRedemption: lastRedemption
          ? moment(lastRedemption, "YYYY-MM-DD:hh:mm:ss").format(
              "YYYY/MM/DD HH:mm A"
            )
          : "N/A",
      };

      acc.push(followerData);
      return acc;
    }, []);

    return data;
  };

  return (
    <StyledfollowersList className="component-followers-list" animated fadeIn>
      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="followers-list-header"
        margin="30px 0"
      >
        <DashboardTitle
          title="Following List"
          justifyContent="flex-start"
          backButtonPath={teamId ? `/portal/teams/${teamId}/dashboard` : ""}
          className="standard-h5 blue"
        />
        {team && (
          <DashboardHeader
            logo={team.logo}
            name={team.name}
            email={team.displayEmail}
            profileUrl={team.website}
            followersTotal={followers ? followers.length : 0}
            hideEl
            className="logo-followers-list-header"
          />
        )}
        <div className="link-container">
          {followers && followers.length > 0 && (
            <CSVExport
              data={generateData()}
              headers={generateHeaders()}
              filename={
                team && team.slug
                  ? `${team.slug}-followers-list-${moment().format(
                      "YYYY-MM-DD"
                    )}`
                  : `followers-list-${moment().format("YYYY-MM-DD")}`
              }
              target="_blank"
              className="rubik-h3"
            >
              <Icon icon="fa-solid:file-export" className="user-logo-icon" />{" "}
              Export Data
            </CSVExport>
          )}
        </div>
      </FlexContainer>
      <FlexContainer>
        {!followers ? (
          <div className="no-followers">
            <SpinLoader />
          </div>
        ) : followers && followers.length > 0 ? (
          <DashboardFollowersTable followers={followers} />
        ) : (
          <div className="no-followers">No followers yet.</div>
        )}
      </FlexContainer>
    </StyledfollowersList>
  );
};

export default withFirebase(FollowersList);
