import React from "react";
import {
  DashboardButton,
  DashboardCard,
  FlexContainer,
  SpinLoader,
} from "../../assets/fansaves-ui";
import styled from "styled-components/macro";
import "../../scss/base/typography.scss";
import { Icon } from "@iconify/react";

const StyledDashboardListCard = styled(FlexContainer)``;

const StyledDashboardCard = styled(DashboardCard)`
  padding: 10px 15px;
  margin: 10px 0;
`;

const Row = styled.div`
  display: grid;
  margin: 10px 0;
  column-gap: 10px;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: ${(props) =>
    props.columns === 3 ? "1fr 5fr 1fr" : "1fr 6fr"};
  grid-template-areas: ${(props) =>
    props.columns === 3 ? '"image name type"' : '"image name"'};
  .dashboard-list-card-item-image-container {
    grid-area: image;
  }
  .dashboard-list-card-item-name {
    grid-area: name;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start;
    width: 100%;
  }
  .dashboard-list-card-item-type-icon {
    grid-area: type;
  }
`;

const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  img.dashboard-list-card-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DashboardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const DashboardListCard = ({ list, title, total, columns, onClick }) => {
  const renderIcon = (deal) => {
    if (deal.triggered) {
      return <Icon icon="bi:bell-fill" />;
    } else if (deal.active) {
      if (deal.flashDeal) {
        return <Icon icon="fa6-solid:bolt-lightning" />;
      } else if (deal.exclusive) {
        return <Icon icon="fa:lock" />;
      }
      return <Icon icon="fa-solid:shopping-bag" />;
    } else {
      return <Icon icon="fa-solid:shopping-bag" />;
    }
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        height="220px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };

  return (
    <StyledDashboardListCard className="dashboard-list-card-container">
      <FlexContainer
        direction="horizontal"
        justifyContent="space-between"
        alignItems="center"
        width="90%"
      >
        <div className="dashboard-list-card-title-text rubik-h3">{title}</div>
        <FlexContainer
          direction="horizontal"
          className="dashboard-list-card-total rubik-h5"
          justifyContent="flex-end"
        >
          Total: <span data-notranslate>{total}</span>
        </FlexContainer>
      </FlexContainer>
      <StyledDashboardCard className="dashboard-list-card">
        {list ? (
          list.length > 0 ? (
            list.map((item, index) => {
              if (index < 6) {
                return (
                  <Row
                    key={index}
                    className="dashboard-list-card-item"
                    columns={columns}
                  >
                    <ImageWrapper className="dashboard-list-card-item-image-container">
                      {title !== "Organizations"
                        ? item.picture && (
                            <img
                              src={item.picture}
                              alt={item.description}
                              className="dashboard-list-card-item-image"
                            />
                          )
                        : item.logo && (
                            <img
                              src={item.logo}
                              alt={item.name}
                              className="dashboard-list-card-item-image"
                            />
                          )}
                    </ImageWrapper>
                    <div
                      className="dashboard-list-card-item-name public-h2"
                      data-notranslate
                    >
                      {item.name || item.description}
                    </div>
                    {title === "Deals" && (
                      <div className="dashboard-list-card-item-type-icon">
                        {renderIcon(item)}
                      </div>
                    )}
                  </Row>
                );
              }
            })
          ) : (
            <div className="dashboard-list-card-empty">No {title} yet</div>
          )
        ) : (
          renderLoadingContainer()
        )}
      </StyledDashboardCard>
      <DashboardButtonContainer>
        <DashboardButton
          title="View All"
          size="small"
          type="shadow-white"
          className="dashboard-list-card-view-all-button"
          onClick={() => onClick()}
        />
      </DashboardButtonContainer>
    </StyledDashboardListCard>
  );
};

export default DashboardListCard;
