/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withFirebase } from "../../../../../containers/Firebase";
import { withRouter } from "react-router-dom";
import {
  isAdmin,
  isSponsor,
  isTeam,
  withAuthentication,
} from "../../../../../containers/Session";
import "../../../../../scss/base/typography.scss";
import { DashboardStats } from "../../../../../containers/UIKit";
import DashboardDealList from "../../../../../containers/DashboardDealList";
import { DashboardHeader } from "../../../../../containers/UIKit";

import {
  DashboardButton,
  DashboardFilter,
  DashboardTitle,
  FlexContainer,
  Pagination,
  SearchBar,
  SpinLoader,
} from "../../../../../assets/fansaves-ui";

import { sortOffersByDate } from "../../../../../utilities/dashboard_helpers";

import useRedemptionsStore from "../../../../../stores/redemptionsStore";
import useTeamsStore from "../../../../../stores/teamsStore";
import useSponsorsStore from "../../../../../stores/sponsorsStore";

import "./List.scss";

const List = ({ firebase, match, authUser, history }) => {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const fetchRedemptionsByChunks = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const sponsorFromStore = useSponsorsStore((state) => state.sponsor);
  const fetchSponsor = useSponsorsStore((state) => state.fetchSponsor);
  const [offers, setOffers] = useState(null);
  const [team, setTeam] = useState(null);
  const [sponsor, setSponsor] = useState(null);
  const [paginatedOffers, setPaginatedOffers] = useState(null);
  const [publishedDeals, setPublishedDeals] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [triggeredDeals, setTriggeredDeals] = useState(null);
  const [flashDeals, setFlashDeals] = useState(null);
  const [archivedDeals, setArchivedDeals] = useState(null);
  const [contestDeals, setContestDeals] = useState(null);

  const [pageNumber, setPageNumber] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const teamId = match.params.team_id;
  const sponsorId = match.params.sponsor_id;

  useEffect(() => {
    if (sponsorFromStore && sponsorFromStore[sponsorId]) {
      setSponsor(sponsorFromStore[sponsorId]);
    }
  }, [sponsorFromStore, sponsorId]);

  useEffect(() => {
    const teamKey =
      teamId || (sponsor && sponsor.teams && Object.keys(sponsor.teams)[0]);
    if (teamFromStore && teamKey && teamFromStore[teamKey]) {
      setTeam(teamFromStore[teamKey]);
    }
  }, [teamFromStore, teamId, sponsor]);

  useEffect(() => {
    const teamKey =
      teamId || (sponsor && sponsor.teams && Object.keys(sponsor.teams)[0]);
    if (!teamKey) {
      setTeam(null);
    } else if (!team || team.key !== teamKey) {
      fetchTeam(firebase, teamKey);
    }
  }, [fetchTeam, firebase, team, teamId, sponsor]);

  useEffect(() => {
    if (!redemptionsFromEvents) {
      if (authUser && isAdmin(authUser)) {
        fetchRedemptionsByChunks(firebase);
      } else {
        fetchRedemptions(firebase);
      }
    }
  }, [
    fetchRedemptions,
    firebase,
    redemptionsFromEvents,
    authUser,
    fetchRedemptionsByChunks,
  ]);

  useEffect(() => {
    if (!sponsorId) {
      setSponsor(null);
      return;
    } else if (!sponsor || sponsor.key !== sponsorId) {
      fetchSponsor(firebase, sponsorId);
    }
  }, [fetchSponsor, firebase, sponsor, sponsorId]);

  useEffect(() => {
    let unsubscribeOffers;
    if (isAdmin(authUser)) {
      unsubscribeOffers = firebase.firestore
        .collection("offers")
        .onSnapshot((snapshot) => {
          const offers = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          if (teamId) {
            setOffers(offers.filter((offer) => offer.teams?.includes(teamId)));
          } else if (sponsorId) {
            setOffers(
              offers.filter((offer) => offer.sponsor?.sponsorKey === sponsorId)
            );
          } else {
            setOffers(offers);
          }
        });
    } else if (isTeam(authUser)) {
      unsubscribeOffers = firebase.firestore
        .collection("offers")
        .where("teams", "array-contains", authUser.teamID)
        .onSnapshot((snapshot) => {
          const offers = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          if (sponsorId) {
            setOffers(
              offers.filter((offer) => offer.sponsor?.sponsorKey === sponsorId)
            );
          } else {
            setOffers(offers.filter((offer) => offer.teams?.includes(teamId)));
          }
        });
    } else if (isSponsor(authUser)) {
      unsubscribeOffers = firebase.firestore
        .collection("offers")
        .where("sponsor.sponsorKey", "==", authUser.sponsorID)
        .onSnapshot((snapshot) => {
          const offers = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setOffers(
            offers.filter((offer) => offer.sponsor?.sponsorKey === sponsorId)
          );
        });
    }

    return () => {
      if (unsubscribeOffers && typeof unsubscribeOffers === "function") {
        unsubscribeOffers();
      }
    };
  }, [firebase, authUser]);

  useEffect(() => {
    if (redemptionsFromEvents) {
      if (isAdmin(authUser)) {
        if (teamId) {
          setRedemptions(
            redemptionsFromEvents.filter(
              (redemption) => redemption.teamID === teamId
            )
          );
        } else if (sponsorId) {
          setRedemptions(
            redemptionsFromEvents.filter(
              (redemption) => redemption.sponsorID === sponsorId
            )
          );
        } else {
          setRedemptions(redemptionsFromEvents);
        }
      } else if (isTeam(authUser)) {
        if (sponsorId) {
          setRedemptions(
            redemptionsFromEvents.filter(
              (redemption) => redemption.sponsorID === sponsorId
            )
          );
        } else {
          setRedemptions(
            redemptionsFromEvents.filter(
              (redemption) => redemption.teamID === authUser.teamID
            )
          );
        }
      } else if (isSponsor(authUser)) {
        setRedemptions(
          redemptionsFromEvents.filter(
            (redemption) => redemption.sponsorID === authUser.sponsorID
          )
        );
      }
    }
  }, [redemptionsFromEvents, teamId, sponsorId, authUser]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  let stats = [
    {
      label: "Published Deals",
      value: publishedDeals
        ? publishedDeals.length > 0
          ? publishedDeals.length
          : 0
        : renderLoading(),
    },
    {
      label: "Redemptions",
      value: redemptions
        ? redemptions.filter(
            (redemption) => redemption.transactionType === "redemption"
          ).length
        : renderLoading(),
    },
    {
      label: "Contest Entries",
      value: redemptions
        ? redemptions.filter(
            (redemption) => redemption.transactionType === "contest"
          ).length
        : renderLoading(),
    },
    {
      label: "Triggered Deals",
      value: triggeredDeals
        ? triggeredDeals.length > 0
          ? triggeredDeals.length
          : 0
        : renderLoading(),
    },
    {
      label: "Flash Deals",
      value: flashDeals
        ? flashDeals.length > 0
          ? flashDeals.length
          : 0
        : renderLoading(),
    },
    {
      label: "Contests",
      value: contestDeals
        ? contestDeals.length > 0
          ? contestDeals.length
          : 0
        : renderLoading(),
    },
    {
      label: "Archived Deals",
      value: archivedDeals
        ? archivedDeals.length > 0
          ? archivedDeals.length
          : 0
        : renderLoading(),
    },
  ];

  const getPageNumberAndQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const pageNumber = search.page ? parseInt(search.page) : 1;
    const filter = search.filter ? search.filter : "NewestToOldest";
    const query = search.query ? search.query : null;

    setPageNumber(pageNumber);
    setSelectedFilter(filter);
    if (query) {
      setSearchQuery(query);
    }

    return { pageNumber, filter, query };
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (selectedFilter) {
      params.filter = selectedFilter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    if (pageNumber) {
      params.page = pageNumber;
    }

    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };

  const updateTotalPages = (list) => {
    const totalPages = Math.ceil(list.length / 20);
    setTotalPages(totalPages);
  };

  const getPaginatedOffers = (pageNumber, list) => {
    if (list) {
      const start = (pageNumber - 1) * 20;
      const end = start + 20;
      const paginatedOffers = list.slice(start, end);
      return paginatedOffers;
    }
  };

  const filterDealsByType = () => {
    if (offers) {
      const publishedDeals = offers.filter((offer) => {
        return offer.published;
      });
      setPublishedDeals(publishedDeals);

      const triggeredDeals = offers.filter((offer) => {
        return offer.triggered;
      });
      setTriggeredDeals(triggeredDeals);

      const flashDeals = offers.filter((offer) => {
        return offer.flashDeal;
      });
      setFlashDeals(flashDeals);

      const archivedDeals = offers.filter((offer) => {
        return !offer.published;
      });
      setArchivedDeals(archivedDeals);

      const contestDeals = offers.filter((offer) => {
        return offer.contestDeal;
      });
      setContestDeals(contestDeals);
    }
  };

  const filterDeals = () => {
    if (!offers || !selectedFilter) {
      return;
    }
    const filteredDeals = [];

    if (selectedFilter === "NewestToOldest") {
      filteredDeals.push(...sortOffersByDate(offers, "desc"));
    } else if (selectedFilter === "OldestToNewest") {
      filteredDeals.push(...sortOffersByDate(offers, "asc"));
    } else if (selectedFilter === "OnlineDeals") {
      const onlineDeals = offers.filter((deal) => deal.dealType === "online");
      filteredDeals.push(...sortOffersByDate(onlineDeals, "desc"));
    } else if (selectedFilter === "InStoreDeals") {
      const inStoreDeals = offers.filter((deal) => deal.dealType === "instore");
      filteredDeals.push(...sortOffersByDate(inStoreDeals, "desc"));
    } else if (selectedFilter === "ExclusiveDeals") {
      const exclusiveDeals = offers.filter((deal) => deal.exclusive);
      filteredDeals.push(...sortOffersByDate(exclusiveDeals, "desc"));
    } else if (selectedFilter === "FlashDeals") {
      const flashDeals = offers.filter((deal) => deal.flashDeal);
      filteredDeals.push(...sortOffersByDate(flashDeals, "desc"));
    } else if (selectedFilter === "TriggeredDeals") {
      const triggeredDeals = offers.filter((deal) => deal.triggered);
      filteredDeals.push(...sortOffersByDate(triggeredDeals, "desc"));
    } else if (selectedFilter === "ArchivedDeals") {
      const archivedDeals = offers.filter((deal) => !deal.published);
      filteredDeals.push(...sortOffersByDate(archivedDeals, "desc"));
    } else if (selectedFilter === "ContestDeals") {
      const contestDeals = offers.filter((deal) => deal.contestDeal);
      filteredDeals.push(...sortOffersByDate(contestDeals, "desc"));
    }

    const paginatedOffers = getPaginatedOffers(pageNumber, filteredDeals);
    setPaginatedOffers(paginatedOffers);
    updateTotalPages(filteredDeals);
  };

  const handleSearch = () => {
    if (!searchQuery) {
      return;
    }

    if (offers && offers.length > 0) {
      const updatedOffers = offers.filter((offer) => {
        if (offer.description) {
          return offer.description
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }

        return false;
      });

      if (updatedOffers.length > 20) {
        const paginatedOffers = getPaginatedOffers(pageNumber, updatedOffers);
        setPaginatedOffers(paginatedOffers);
      } else {
        setPaginatedOffers(updatedOffers);
      }

      updateTotalPages(updatedOffers);
    }
  };

  useEffect(() => {
    getPageNumberAndQueryFromSearch();
  }, []);

  useEffect(() => {
    if (offers) {
      filterDealsByType();
      filterDeals();
    }
  }, [offers]);

  useEffect(() => {
    handleSearch();
    setUrlQueryFromFilters();
  }, [searchQuery]);

  useEffect(() => {
    filterDeals();
    setUrlQueryFromFilters();
  }, [selectedFilter, pageNumber]);

  const handlePageChange = (pageNumber) => {
    document.getElementsByTagName("body")[0].scrollIntoView();
    setPageNumber(pageNumber);
    filterDeals();
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setPageNumber(1);
    setSearchQuery(null);
  };

  const getBackButtonPath = () => {
    if (isAdmin(authUser)) {
      if (sponsor) {
        if (sponsorId) {
          return `/portal/sponsors/${sponsorId}/dashboard`;
        } else {
          return "";
        }
      } else if (team) {
        if (teamId) {
          return `/portal/teams/${teamId}/dashboard`;
        } else {
          return "";
        }
      } else {
        return `/portal/dashboard`;
      }
    } else if (isSponsor(authUser)) {
      if (sponsor) {
        if (sponsorId) {
          return `/portal/sponsors/${sponsorId}/dashboard`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else if (isTeam(authUser)) {
      if (team) {
        if (teamId) {
          return `/portal/teams/${teamId}/dashboard`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };

  return (
    <div className="component-sponsor-deals-list">
      <FlexContainer
        direction="horizontal"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        responsive
      >
        <FlexContainer
          alignItems="flex-start"
          width="100%"
          className="deal-list-header"
          gap="30px"
          responsive
        >
          <DashboardTitle
            title="Deals"
            justifyContent="flex-start"
            backButtonPath={getBackButtonPath()}
            className="standard-h5 blue"
          />
          {sponsor ? (
            <FlexContainer
              direction="horizontal"
              justifyContent="space-between"
              width="200%"
              responsive
              mobileDirection="column-reverse"
            >
              <DashboardHeader
                logo={sponsor.picture}
                name={sponsor.name}
                email={sponsor.displayEmail}
                description={sponsor.description}
                profileUrl={sponsor.url}
                hideEl
              />
              {team && (
                <DashboardHeader
                  logo={team.logo}
                  name={team.name}
                  email={team.displayEmail}
                  profileUrl={team.website}
                  hideEl
                  secondaryHeader
                />
              )}
            </FlexContainer>
          ) : (
            team && (
              <FlexContainer alignItems="flex-start" gap="30px" responsive>
                <DashboardHeader
                  logo={team.logo}
                  name={team.name}
                  email={team.displayEmail}
                  profileUrl={team.website}
                  hideEl
                  className="logo-deals-list-header"
                />
                <div className="standard-h6 blue">Businesses</div>
              </FlexContainer>
            )
          )}
          <DashboardButton
            title="Add Deal"
            type="green"
            icon="fa-solid:plus"
            onClick={(e) => {
              e.preventDefault();
              if (isAdmin(authUser)) {
                history.push(
                  `/portal/sponsors/${
                    authUser.sponsorID || sponsorId
                  }/deals/create`
                );
              } else {
                window.open(
                  "https://form.jotform.com/202084089266256",
                  "_blank",
                  "noopener,noreferrer"
                );
              }
            }}
            disabled={isAdmin(authUser) && !sponsorId}
            disabledMessage="Admin can add a deal on a Business Dashboard"
          />
        </FlexContainer>
        <FlexContainer
          margin="30px 0 0 0"
          gap="20px"
          alignItems="flex-end"
          responsive
        >
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder="Search Deals"
          />
        </FlexContainer>
      </FlexContainer>
      <DashboardStats stats={stats} />
      <FlexContainer
        alignItems="flex-end"
        width="100%"
        className="deal-list-pagination"
      >
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          className={totalPages > 1 ? "" : "hide"}
        />
      </FlexContainer>
      <FlexContainer className="deals-list">
        <DashboardFilter
          type="deals"
          onSelect={handleFilterChange}
          className="deals-filter"
          initialFilterOption={selectedFilter}
        />
        {!offers && !paginatedOffers ? (
          <div className="no-organizations">
            <SpinLoader />
          </div>
        ) : (
          paginatedOffers &&
          paginatedOffers.length > 0 && (
            <DashboardDealList
              deals={paginatedOffers}
              hideTitle
              className="deal-list"
              hideToggle
            />
          )
        )}
      </FlexContainer>

      <FlexContainer
        justifyContent="center"
        alignItems="center"
        className="no-deals"
      >
        {paginatedOffers && paginatedOffers.length === 0 && (
          <div className="no-deals">No deals found</div>
        )}
      </FlexContainer>
      <FlexContainer
        alignItems="flex-end"
        width="100%"
        className="deal-list-pagination"
        padding="0 0 30px 0"
      >
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          className={totalPages > 1 ? "" : "hide"}
          marginBottom="50px"
        />
      </FlexContainer>
    </div>
  );
};

export default withFirebase(withAuthentication(withRouter(List)));

List.propTypes = {
  firebase: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};
